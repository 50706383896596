import Main from './components/Main';
import Login from './components/Login';
import { useAuth0 } from '@auth0/auth0-react';

export default function App() {
  const { isLoading, isAuthenticated, error } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    return <Main />;
  } else {
    return <Login error={error && error.message} />;
  }
}
