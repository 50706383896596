import styles from './List.module.css';

export default function List({
  children,
  top,
  left,
  bottom,
  right,
  round,
  style,
  className,
  ...props
}) {
  return (
    <div
      className={`${styles.list}`}
      {...props}
    >
        <ul>
            {children}
        </ul>
    </div>
  );
}
