import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Map from './Map';
import Panel from './Panel';
import { useAuth0 } from '@auth0/auth0-react';
import geojsonData from '../data.geojson';
import styles from './Main.module.css';
import List from './List';

export default function Main() {
  const { isLoading, user, logout } = useAuth0();
  const [userLoading, setUserLoading] = useState(true);
  const [userLocation, setUserLocation] = useState();
  const [geojson, setGeojson] = useState(null);
  const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' });

  useEffect(() => {
    fetch(geojsonData)
      .then((response) => response.json())
      .then((geojson) => {
        setGeojson(geojson);
        const userName = user.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        geojson.features.forEach((feature) => {
          feature.properties.team.forEach((member) => {
            const teamName = member.name
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '');
            if (teamName === userName) {
              setUserLocation(feature.properties.place);
            }
          });
        });
        setUserLoading(false);
      });
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleUserClick = (e) => {
    e.preventDefault();
    logout({ returnTo: window.location.origin });
  };

  function getPlacesByState(geojson) {
    let places = [];
    function getPlaceProps(feature) {
      const [state, country] = feature.properties.place.split(',').slice(-2);
      return { country: country.trim(), state: state.trim(), name: `${state.trim()}, ${country.trim()}` };      
    }
    geojson.features.forEach((feature) => {
      const placeProps = getPlaceProps(feature);
      let place = places.find(place => place.name === placeProps.name);
      if (!place) {
        place = {
          ...placeProps,
          team: feature.properties.team
        }
        places.push(place);
      } else {
        feature.properties.team.forEach((member) => {
          let exisingMember = place.team.find((m) => m.name === member.name);
          if (!exisingMember) {
            place.team.push(member);
          }
        });
      }
    })
    return places.sort(sortBy('state')).sort(sortBy('country'));
  }

  const sortBy = prop => (a, b) => {
    return a[prop].localeCompare(b [prop]);
  }

  return (
    <>
        <Panel
          top={isBigScreen ? 16 : 0}
          right={!isBigScreen && 0}
          left={isBigScreen ? 16 : 0}
          bottom={isBigScreen ? 16 : undefined}
          round={isBigScreen}
        >
          <a href="/">
            <div className={styles.logoContainer}>
              <img
                src="/kelsus-map-logo.svg"
                alt="Kelsus Map"
                className={styles.logo}
              />
            </div>
          </a>
          <List>{
              geojson ? getPlacesByState(geojson)
                .map((place) => {
                  return (<li key={place.name}>
                    <h4>{place.name}</h4>
                    <ul>
                      {place.team.sort(sortBy('name')).map((member) => <li key={member.i}>{member.name}</li>)}
                    </ul>
                  </li>)
                }) : null
            }</List>
        </Panel>
      {!userLoading && (
        <Panel
          top={isBigScreen && 16}
          bottom={!isBigScreen && 0}
          left={!isBigScreen && 0}
          right={isBigScreen ? 16 : 0}
          round={isBigScreen}
          style={!isBigScreen && { paddingBottom: 32 }}
        >
          <div className={styles.userContainer}>
            {user.picture && (
              <img src={user.picture} alt="" className={styles.userPicture} />
            )}
            <div className={styles.userText}>
              <p className={styles.userName}>{user.name}</p>
              {userLocation && (
                <p className={styles.userLocation}>{userLocation}</p>
              )}
              <a
                href="/logout"
                onClick={handleUserClick}
                className={styles.logout}
              >
                Log out
              </a>
            </div>
          </div>
        </Panel>
      )}
      <Map />
    </>
  );
}
