import React, { useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import timespace from '@mapbox/timespace';
import geojsonData from '../data.geojson';
import styles from './Map.module.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function Map({ onPlaceClick }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/josefernand/cl3jap99j003y15paldqytyr3',
      zoom: 1.7,
    });

    map.current.on('load', () => {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.

      map.current.addSource('team_places', {
        type: 'geojson',
        data: geojsonData,
        cluster: true,
        clusterProperties: {
          sum: ['+', ['get', 'size']],
        },
      });

      map.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'team_places',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#008ad8',
          'circle-radius': 24,
        },
      });

      map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'team_places',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{sum}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': 'white',
        },
      });

      map.current.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'team_places',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#008ad8',
          'circle-radius': 12,
        },
      });

      map.current.addLayer({
        id: 'unclustered-count',
        type: 'symbol',
        source: 'team_places',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': '{size}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': 'white',
        },
      });

      // inspect a cluster on click
      map.current.on('click', 'clusters', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        map.current
          .getSource('team_places')
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      map.current.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const team = JSON.parse(e.features[0].properties.team).sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const timestamp = Date.now();
        const time = timespace.getFuzzyLocalTimeFromPoint(
          timestamp,
          coordinates
        );
        const timezone = `UTC${time.format('Z')}`;

        let htmlTeam = `<p class="place-title">${e.features[0].properties.text}</br><small>(${timezone})</small></p>`;
        for (let index = 0; index < team.length; index++) {
          const element = team[index];
          htmlTeam = htmlTeam + `<p class="team-member">${element.name}</p>`;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(htmlTeam)
          .addTo(map.current);

        if (onPlaceClick) {
          onPlaceClick({ place: e.features[0].properties.text, team });
        }
      });

      map.current.on('mouseenter', ['clusters', 'unclustered-point'], () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseleave', ['clusters', 'unclustered-point'], () => {
        map.current.getCanvas().style.cursor = '';
      });

      fetch(geojsonData)
        .then((response) => response.json())
        .then((geojson) => {
          const coordinates = geojson.features.map(
            (f) => f.geometry.coordinates
          );

          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of coordinates) {
            bounds.extend(coord);
          }

          map.current.fitBounds(bounds, {
            padding: 100,
          });
        });
    });
  }, [onPlaceClick]);

  return <div ref={mapContainer} className={styles.container} />;
}
