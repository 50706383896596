import { useAuth0 } from '@auth0/auth0-react';
import Button from './Button';
import Alert from './Alert';
import styles from './Login.module.css';

export default function Login({ error }) {
  const { isLoading, loginWithRedirect, logout } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <img
        src="/kelsus-map-logo.svg"
        className={styles.logo}
        alt="Kelsus Map"
      />
      {!error ? (
        <Button onClick={loginWithRedirect}>Log in with Kelsus</Button>
      ) : (
        <>
          <Alert>Error: {error}</Alert>
          <Button onClick={() => logout({ returnTo: window.location.origin })}>
            Log out
          </Button>
        </>
      )}
    </div>
  );
}
