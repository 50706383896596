import styles from './Button.module.css';

export default function Button({
  children,
  solid,
  small,
  className,
  ...props
}) {
  const solidStyle = solid ? styles.solid : '';
  const smallStyle = small ? styles.small : '';

  return (
    <button
      className={`${styles.button} ${smallStyle} ${solidStyle} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}
