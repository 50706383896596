import styles from './Panel.module.css';

export default function Panel({
  children,
  top,
  left,
  bottom,
  right,
  round,
  style,
  className,
  ...props
}) {
  const positionStyle = { top, left, bottom, right };
  const roundStyle = round ? styles.round : '';
  return (
    <div
      className={`${styles.panel} ${roundStyle} ${className}`}
      style={{ ...positionStyle, ...style }}
      {...props}
    >
      {children}
    </div>
  );
}
